html {
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 8px;
    height: 12px;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 4px;
  }
}

.display-none {
  display: none;
}

.one-line-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}