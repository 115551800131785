@import "../../assets/style/media.less"; // 媒体查询
@import "../../assets/style/classname.less"; // 类名
// The prefix to use on all css classes from ant.
@ant-prefix: khAnt;

@primary-color: #2c55a4; // 全局主色
// @link-color: #1890ff; // 链接色
// @success-color: #52c41a; // 成功色
// @warning-color: #faad14; // 警告色
// @error-color: #f5222d; // 错误色
// @font-size-base: 14px; // 主字号
// @heading-color: rgba(0, 0, 0, 0.85); // 标题色
// @text-color: rgba(0, 0, 0, 0.65); // 主文本色
// @text-color-secondary: rgba(0, 0, 0, 0.45); // 次文本色
// @disabled-color: rgba(0, 0, 0, 0.25); // 失效色
// @border-radius-base: 2px; // 组件/浮层圆角
// @border-color-base: #d9d9d9; // 边框色
// @box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
//   0 9px 28px 8px rgba(0, 0, 0, 0.05); // 浮层阴影
@{TWBaseNoticelPrefixCls} {
  display: flex;
  flex-direction: column;
  height: 400px;
  

  &-list {
    flex: 1;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }
    &::-webkit-scrollbar-thumb {
      width: 0;
    }

    .@{ant-prefix}-list-item {
      padding: 12px 24px;
      cursor: pointer;
      transition: all .3s;

      &:hover {
        background: #d5dce3;
      }
    }

    h4 {
      margin: 0 0 4px 0;
    }
  }

  &-tools {
    height: 46px;
    display: flex;

    > button {
      flex: 1;
      height: 46px;
    }

    > .@{ant-prefix}-divider {
      height: 100%;
    }
  }
}