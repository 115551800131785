@import "../../assets/style/media.less"; // 媒体查询
@import "../../assets/style/classname.less"; // 类名
// The prefix to use on all css classes from ant.
@ant-prefix: khAnt;

@primary-color: #2c55a4; // 全局主色
// @link-color: #1890ff; // 链接色
// @success-color: #52c41a; // 成功色
// @warning-color: #faad14; // 警告色
// @error-color: #f5222d; // 错误色
// @font-size-base: 14px; // 主字号
// @heading-color: rgba(0, 0, 0, 0.85); // 标题色
// @text-color: rgba(0, 0, 0, 0.65); // 主文本色
// @text-color-secondary: rgba(0, 0, 0, 0.45); // 次文本色
// @disabled-color: rgba(0, 0, 0, 0.25); // 失效色
// @border-radius-base: 2px; // 组件/浮层圆角
// @border-color-base: #d9d9d9; // 边框色
// @box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
//   0 9px 28px 8px rgba(0, 0, 0, 0.05); // 浮层阴影
#main-root @{TWBasePrefixCls} {

  // logo部分
  &-logo {
    display: flex;
    align-items: center;
    height: 100%;
    min-width: 444px;
  
    img {
      display: inline-block;
      height: 32px;
      vertical-align: middle;
    }
  
    h1 {
      display: inline-block;
      margin: 0 8px 0 16px;
      color: #fff;
      font-size: 18px;
      vertical-align: top;
      max-width: 242px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: normal;
    }
  }

  // 页面公共头部
  &-header {
    @headerH: 48px;

    display: flex;
    align-items: center;
    height: @headerH;
    line-height: @headerH;
    padding: 0 24px;

    &-menu {
      flex: 1;
      overflow: hidden;

      > ul {

        > li {
          height: @headerH;
          line-height: @headerH;
          font-size: 16px;
        }
      }
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: right;
      min-width: 220px;
      text-align: right;

      &_avatar {
        display: flex;
        align-items: center;
      }

      &_btn {
        font-size: 16px;
        cursor: pointer;
        color: #fff !important;
      }
    }
  }

  &-wrap {
    flex: 1;
    width: 100% !important;

    &_right {
      height: 100%;

      > .@{ant-prefix}-spin-nested-loading {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .@{ant-prefix}-spin-container {
          flex: 1;
          display: flex;
          flex-direction: column;
          overflow: hidden;
        }
      }
    }
  }

  // 左侧菜单
  &-sider {
    overflow: hidden;

    &_menu {
      // @width: 210px;
      // width: @width !important;
      // max-width: @width !important;
      // flex-basis: @width !important;
    }

    &_card {
      // @width: 400px;
      // width: @width !important;
      // max-width: @width !important;
      // flex-basis: @width !important;
    }
  }

  &-app-container {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

html, body, #main-root {
  height: 100%;
}
