@xxl-width: 1920px - 20; // 1
@xl-width: 1680px - 20; // 0.85
@lg-width: 1536px - 20; // 0.8
@md-width: 1440px - 20; // 0.75
@sm-width: 1280px - 20; // 0.65

.responsive(@size, @content) when (default()) {
  // >= 1920px
  @media screen and (min-width: @xxl-width) {
    @content();
  }
}

.responsive(@size, @content) when (@size = 'xl') {
  // 1680px ~ 1920px
  @media (min-width: @xl-width) and (max-width: (@xxl-width - 1)) {
    @content();
  }
}

.responsive(@size, @content) when (@size = 'lg') {
  // 1536px ~ 1680px
  @media (min-width: @lg-width) and (max-width: (@xl-width - 1)) {
    @content();
  }
}

.responsive(@size, @content) when (@size = 'md') {
  // 1440px ~ 1536px
  @media (min-width: @md-width) and (max-width: (@lg-width - 1)) {
    @content();
  }
}

.responsive(@size, @content) when (@size = 'sm') {
  // 1280px ~ 1440px
  @media (min-width: @sm-width) and (max-width: (@md-width - 1)) {
    @content();
  }
}

.responsive(@size, @content) when (@size = 'xs') {
  // < 1280px
  @media (max-width: (@sm-width - 1)) {
    @content();
  }
}
