@import "media.less"; // 媒体查询
@import "classname.less"; // 类名
// The prefix to use on all css classes from ant.
@ant-prefix: khAnt;

@primary-color: #2c55a4; // 全局主色
// @link-color: #1890ff; // 链接色
// @success-color: #52c41a; // 成功色
// @warning-color: #faad14; // 警告色
// @error-color: #f5222d; // 错误色
// @font-size-base: 14px; // 主字号
// @heading-color: rgba(0, 0, 0, 0.85); // 标题色
// @text-color: rgba(0, 0, 0, 0.65); // 主文本色
// @text-color-secondary: rgba(0, 0, 0, 0.45); // 次文本色
// @disabled-color: rgba(0, 0, 0, 0.25); // 失效色
// @border-radius-base: 2px; // 组件/浮层圆角
// @border-color-base: #d9d9d9; // 边框色
// @box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
//   0 9px 28px 8px rgba(0, 0, 0, 0.05); // 浮层阴影
@import (less) "./normalize.css";
@import "./base.less";

// 字体图标
@import "./../fonts/iconfont/iconfont.css";


// driver.js组件样式，配合子应用
@import 'driver.js/dist/driver.min.css';

@import "~antd/es/style/themes/default.less";
@import "~antd/dist/antd.less"; // 引入官方提供的 less 样式入口文件
@import "./theme.less"; // 用于覆盖上面定义的变量
@import "./antd.less";
// 
.list-ctn {

  .el-table__row .el-radio__label {
    display: none;
    color: transparent!important;
  }
}